import styled from "styled-components"
import { Link } from "gatsby"
import media from "styled-media-query"
import ReactLoading from "react-loading"

export const Container = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Section = styled.section`
  display: flex;
  height: calc(100vh - 78px);
  max-height: calc(100vh - 78px);
  justify-content: space-between;
  flex-direction: column;

  ${media.greaterThan("medium")`
    width: 450px;
    margin: auto;
    justify-content: flex-start;
  `}
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 20px;
  align-items: center;
`

export const ContainerVideo = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0px 20px;
`

export const Profile = styled.div`
  margin-top: 50px;
  margin-left: 30px;
  width: 60px;
  height: 60px;

  ${media.lessThan("medium")`
    width: 60px;
    height: 60px;
    margin-top: 25px;
    margin-left: 28px;
    
  `}
`

export const User = styled.img`
  src: url(${props => props.src});
`

export const Form = styled.div`
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 25px;
`

export const Label = styled.div`
  p {
    font-size: 15px;
    font-family: Poppins;
    font-weight: 500;
    color: ${props => props.theme.colors.greenDarker};
  }

  span {
    font-size: 15px;
    font-weight: bold;
    line-height: 25px;
    color: ${props => props.theme.colors.greenDarker};
  }
`

export const Input = styled.input`
  padding-left: 15px;
  width: 100%;
  height: 48px;
  margin-top: 25px;
  border-radius: 10px;
  border: solid 1px rgba(44, 45, 44, 0.3);
  background-color: #ffffff;

  ${media.lessThan("medium")`
    margin-top: 10px;
  `}
`

export const Button = styled.button`
  display: flex;
  border-radius: 10px;
  padding: 14px 0px;
  border-style: outset;
  border: none;
  background-color: ${props => props.theme.colors.green};
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.colors.charcoal};
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  flex: 1; 

  &:disabled {
    background-color: ${props => props.theme.colors.greenLighter};
    opacity: 0.6;
  }

  &:hover {
    background-color: ${props => props.theme.colors.greenDark};
  }
`

export const BackButton = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.colors.charcoal};
  padding: 13px 0px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.charcoal};
  flex: 1;
  justify-content: center;
  align-items: center;
`
export const LatterIcon = styled.img`
  src: url(${props => props.src});
  width: 22px;
  height: 22px;
  margin-right: 15px;
`
export const ContainerDeal = styled.div`
  display: flex;
  box-shadow: 0px -3px 15px #00000029;
  width: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  flex-direction: column;
  padding: 0px 15px;
`

export const HeaderDeal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-bottom-width: 2px;
  border-bottom-color: #d9d9d9;
  border-bottom-style: solid;
  padding: 20px 0px;

  p {
    color: #2C2D2C;
    font-size: 14px;
    font-family: Poppins;
    margin: 10px 0px;
    font-weight: 500;
    opacity: 0.7;
  }

  span {
    color: ${props => props.theme.colors.greenDarker};
    font-size: 28px;
    font-family: Poppins;
    font-weight: bold;
  }

  strong {
    font-weight: bold;
  }
`

export const PortionDeal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 30px 0px;
  align-items: center;
  border-bottom-width: 2px;
  border-bottom-color: #d9d9d9;
  border-bottom-style: solid;
  justify-content: space-between;
  
  p {
    color: #2C2D2C;
    font-size: 14px;
    font-family: Poppins;
    margin: 10px 0px;
    font-weight: 500;
    opacity: 0.7;
  }

  span {
    font-size: 23px;
    font-family: Poppins;
    font-weight: bold;
    color: #2C2D2C;
    opacity: 0.7;
  }

  strong {
    font-weight: bold;
  }
`

export const FeeDeal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 30px 0px 12px;
  align-items: center;
  border-bottom-width: 2px;
  border-bottom-color: #d9d9d9;
  border-bottom-style: solid;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 18px;
    align-items: flex-end;
  }

  p {
    color: #2C2D2C;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
    opacity: 0.7;
  }

  span {
    font-size: 14px;
    font-family: Poppins;
    font-weight: bold;
    color: #2C2D2C;
    opacity: 0.7;
  }

  strong {
    font-weight: bold;
  }

  h1 {
    font-size: 22px;
    font-family: Poppins;
    font-weight: bold;
    color: #2C2D2C;
  }
`

export const Loading = styled(ReactLoading).attrs({
  type: 'spin',
  color: '#135434',
  height: 40,
  width: 40
})`
  display: flex;
`;

export function calculateCost(fees, amount, months) {
  const bankCost = fees.BANK_SLIP * months;
  return bankCost + fees.CPF_TAX + fees.BIORC_TED + amount * fees.BIORC_TAX;
}

export function calculateIOF(fees, amount, months) {
  const iofMonth = fees.IOF_TAX * amount
  const iofDaily = fees.IOF_DAILY * 30 * months * amount
  return iofMonth + iofDaily
}

export function calculateFinancedAmount(fees, amount, months) {
  const financedAmount = calculateCost(fees, amount, months);
  return amount + financedAmount + calculateIOF(fees, amount, months);
}

export function calculateInstallmentValue(fees, amount, months) {
  if (!fees) return 0
  const financedAmount = calculateFinancedAmount(fees, amount, months)
  const financeCoeficient =
    fees.INTEREST / (1 - 1 / Math.pow(1 + fees.INTEREST, months))
  return Math.round(financedAmount * financeCoeficient * 100) / 100
}

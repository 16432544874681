import React, { useEffect } from 'react';
import { Redirect } from "@reach/router"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import DealInvitePage from "../sections/dealInvite"

function DealInvite({ location }) {
  // if (!location.state?.deal) {
  //   navigate('/code-invite');
  //   return null;
  // }

  return (
    <Layout>
      <SEO title="Cadastro" />
      <DealInvitePage location={location}/>
    </Layout>
  );
}

export default DealInvite;
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Photo from "../../images/consultor.png"
import Latter from "../../images/latter.png"
import { calculateInstallmentValue } from "../../components/simulator/calculate";
import client from "../../service/graphql";
import querys from "../../service/querys";
import { Redirect } from "@reach/router"

import {
  Section,
  Header,
  HeaderDeal,
  PortionDeal,
  FeeDeal,
  Label,
  ContainerVideo,
  Button,
  BackButton,
  LatterIcon,
  ContainerDeal,
  Loading
} from "./styles"
import { Body, Buttons } from "../../steps/shared-styles"

const About = ({ location }) => {
  const [fees, setFees] = useState(null);
  const [deal, setDeal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const params = new URLSearchParams(location.search);
  console.log('params', params)
  useEffect(() => {
    async function loadData() {
      try {
        // if (!params.get('group')) {
        //   navigate('/')
        // }
        setLoading(true);
        const { getDeal } = await client.request(querys.GET_DEAL, {
          input: {
            identification: params.get('group')
          },
        });
        setDeal(getDeal)

        const obj = {}
        getDeal.fees.forEach(fee => {
          obj[fee.type] = fee.value
        })

        setFees(obj);
      } catch (errors) {
        const code = errors?.response?.errors[0]?.extensions?.code;

        if (code === 'DEAL_IS_COMPLETED') {
          setError(errors?.response?.errors[0]?.message)
        }
      } finally {
        setLoading(false);
      }
    }
    loadData()
  }, [])

  function submit(e) {
    e.preventDefault();
    navigate('/signin-number', { state: { deal } });
  }

  if (loading) {
    return (
      <div style={{ display: 'flex', flex: 1, height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
        <Loading type="spin" />
      </div>
    )
  }

  return (
    <div>
      <form onSubmit={submit}>
        <Section>
          <Body>
            <Header>
              <LatterIcon src={Latter} />
              {deal?.owner.name && (
                <Label><p><span>{deal?.owner.name}</span> te convidou para um crédito em grupo!</p></Label>
              )}
              {error.length > 0 && (
                <Label><p>{error}</p></Label>
              )}
            </Header>
            <ContainerDeal>
              <HeaderDeal>
                <p>Empréstimo de</p>
                <span>{deal?.value ? `R$ ${deal?.value}, 00` : 'R$ -'}</span>
                <p>que deverá ser pago em <strong>{deal?.split} parcelas</strong> de</p>
              </HeaderDeal>

              <PortionDeal>
                <p>Seis parcelas de</p>
                <span>R$ {calculateInstallmentValue(fees, deal?.value, deal?.split).toFixed(2).toLocaleString("pt-BR")}</span>
              </PortionDeal>

              <FeeDeal>
                <div>
                  <p>Taxa</p>
                  <span>{fees?.INTEREST ? `${(fees?.INTEREST * 100).toFixed(1)}% a.m.` : '-'}</span>
                </div>
                <div>
                  <p>Total</p>
                  <h1>{deal?.value ? `R$ ${(calculateInstallmentValue(fees, deal?.value, deal?.split) * deal?.split).toFixed(2).toLocaleString("pt-BR")}` : '-'}</h1>
                </div>
              </FeeDeal>
            </ContainerDeal>
          </Body>
          {deal?.value && (
            <Buttons>
              <BackButton onClick={() => navigate("/faq")} style={{ textDecoration: "none" }}>
                Rejeitar
              </BackButton>
              <Button type="submit">
                Aceitar
              </Button>
            </Buttons>
          )}
        </Section>
      </form>
    </div>
  )
}

export default About
